import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../utils/fontawesome'

const Header = (props) => {

  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return(
    <div>
    <header className="site__header">
      <div className="site__name float-md-start">
        <Link to="/">
          Kent M. Wilhelm
        </Link>
      </div>
      <div className="site__nav d-none d-lg-block">
        <ul className="list-unstyled list-inline">
          <li className="list-inline-item">
            <Link to="/photography"
            activeClassName="active__section"
            partiallyActive={true}
            >
              Photo
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/video"
            activeClassName="active__section"
            partiallyActive={true}
            >
              Video
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/writing"
            activeClassName="active__section"
            partiallyActive={true}
            >
              Writing
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/design"
            activeClassName="active__section"
            partiallyActive={true}
            >
              Design
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/audio"
            activeClassName="active__section"
            partiallyActive={true}
            >
              Audio
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/code"
            activeClassName="active__section"
            partiallyActive={true}
            >
              Code
            </Link>
          </li>
        </ul>
      </div>
      <div className="site__menu__toggle d-lg-none">
        <a onClick={toggle}>
          <FontAwesomeIcon icon={'bars'} />
        </a>
      </div>

    </header>

    <Modal isOpen={modal} toggle={toggle} className="modal__site__nav">
      <ModalBody>
        <div className="modal__site__nav__content">
          <ul className="list-unstyled">
            <li className="mb-3">
              <Link to="/photography"
              activeClassName="active__section"
              partiallyActive={true}
              >
                Photo
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/video"
              activeClassName="active__section"
              partiallyActive={true}
              >
                Video
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/writing"
              activeClassName="active__section"
              partiallyActive={true}
              >
                Writing
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/design"
              activeClassName="active__section"
              partiallyActive={true}
              >
                Design
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/audio"
              activeClassName="active__section"
              partiallyActive={true}
              >
                Audio
              </Link>
            </li>
            <li>
              <Link to="/code"
              activeClassName="active__section"
              partiallyActive={true}
              >
                Code
              </Link>
            </li>
          </ul>
          <div>
            <a
              onClick={toggle}
              className="close__modal"
            >
              <FontAwesomeIcon icon={['far', 'times-circle']} size="2x" />
            </a>
          </div>
        </div>
      </ModalBody>
    </Modal>

  </div>
  )
}

export default Header
