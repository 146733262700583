import React from 'react'
import prismicLogo from '../../images/logo-prismic.svg'

export default () => (
  <footer className="copy__body__center pb-5 pt-5">
    <div className="text-center">
      <span className="made__by">Handmade by KMW in NYC</span>
    </div>
  </footer>
)
